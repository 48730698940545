import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

// Utils
import { NavContext } from '../../utils/navContext';

// Hooks
import useFooter from '../../hooks/ui/useFooter';
import { FadeEachWhenVisible } from '../../utils/fadeEachWhenVisible';
import useRichText from '../../hooks/api/useRichText';

function Footer() {
    const { parseBodyText } = useRichText();
    const { title, copyright, emailItems, socialItems, locationItems } = useFooter();

    const [refNav, inView] = useInView({
        threshold: 0.3
    });
    const { setIsInView } = useContext(NavContext);

    useEffect(() => {
        if (inView) {
            setIsInView('contact');
        }
    }, [inView, setIsInView]);

    return (
        <footer className={'footer'} data-scroll-section>
            <div className={'footer-info'} data-scroll>
                <div className={'address'} ref={refNav}>
                    <h3 className={'footer-title'} id="contact">
                        {title}
                    </h3>

                    <div className={'address-lane lane-width'}>
                        {locationItems.map((item, index) => {
                            const title = item.fields.title;
                            const head = item.fields.headquarter;
                            const locations = parseBodyText(item.fields.location);
                            return (
                                <FadeEachWhenVisible
                                    index={index}
                                    toY={0}
                                    fromY={20}
                                    delayTo={0.3}
                                    delayFrom={0.3}
                                    key={index}
                                    custom={index}>
                                    <div className={'address-lane_item'} key={index}>
                                        <h4>{title}</h4>
                                        <span>{head}</span>
                                        <div className={'address-lane_item-location'}>
                                            {locations}
                                        </div>
                                    </div>
                                </FadeEachWhenVisible>
                            );
                        })}
                    </div>
                </div>

                <div className={'lanebar'}>
                    <div className={'lanebar-social'}>
                        <ul>
                            {socialItems.map((item, index) => (
                                <FadeEachWhenVisible
                                    index={index}
                                    toY={0}
                                    fromY={20}
                                    delayTo={0.2}
                                    delayFrom={0.2}
                                    key={index}
                                    custom={index}>
                                    <li key={index}>
                                        <a
                                            href={item.fields.link}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <img
                                                src={item.fields.image.fields.file.url}
                                                alt={item.fields.image.fields.title}
                                            />
                                            {item.fields.title}
                                        </a>
                                    </li>
                                </FadeEachWhenVisible>
                            ))}
                        </ul>
                    </div>

                    <div className={'lanebar-contact lane-width'}>
                        <div className={'contacts'}>
                            {emailItems.map((item, index) => {
                                const { person, position, secondaryEmail } = item.fields;
                                return (
                                    <FadeEachWhenVisible
                                        index={index}
                                        toY={0}
                                        fromY={20}
                                        delayTo={0.25}
                                        delayFrom={0.25}
                                        key={index}
                                        custom={index}>
                                        <div className={'contactpad'} key={index}>
                                            <h4>{item.fields.title}</h4>
                                            <span>{person}</span>
                                            <p>{position}</p>
                                            {secondaryEmail && (
                                                <a
                                                    href={`mailto: ${secondaryEmail}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    {secondaryEmail}
                                                </a>
                                            )}
                                            <a
                                                href={`mailto: ${item.fields.email}`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                {item.fields.email}
                                            </a>
                                        </div>
                                    </FadeEachWhenVisible>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'footer-copy'}>
                {copyright}
                <span> ► </span>
                <Link to={'/privacy-policy'} className={'footer-privacy'}>
                    Privacy
                </Link>
            </div>
        </footer>
    );
}

export default Footer;
