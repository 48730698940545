import useContentful from '../api/useContentful';

export default function useFooter() {
    const { data, isLoading } = useContentful('2Ax6OPqhC6eGv7T1tIgTOv');

    const title = data?.title || '';
    const copyright = data?.copyright || '';
    const emailItems = data?.emailItems || [];
    const socialItems = data?.socialItems || [];
    const locationItems = data?.locationItems || [];

    return {
        title,
        copyright,
        emailItems,
        socialItems,
        locationItems,
        isLoading
    };
}
